:root {
  --en-font: 'Nunito Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  --jp-font: 'M PLUS 1', cursive;
  --bg-color: rgba(239, 239, 239, 0.5);
  --text-color: #111;
  --jp-text-color: #aaa;
  --link-color: #11038b;
  --jp-link-color: #655eac;
  --success-color: #04a50c;
  --error-color: #b80404;
}

body {
  font-family: var(--en-font);
}

h1 {
  font-weight: 200;
  font-size: 3.052rem;
}

h2, h3, h4 {
  font-weight: 700;
}

h2 {
  font-size: 2.2rem;
}

h3 {
  font-size: 1.8rem;
}

h4,
th {
  font-size: 1.25rem;
}

a,
a:visited {
  color: var(--link-color);
  text-decoration: none;
}

input {
  background-color: var(--bg-color);
  border: 2px solid var(--text-color);
  border-radius: 2px;
  padding: 5px 10px;
}

.large-button {
  background-color: transparent;
  border: 2px solid var(--text-color);
  border-radius: 2px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 20px 30px;
  text-transform: uppercase;
  transition: 100ms background-color linear, 100ms color linear;
}

.large-button:hover {
  background-color: var(--text-color);
  color: #fff;
}

.large-button:active {
  outline: none;
}

.large-button:focus {
  outline: thin dotted;
}

.jp {
  color: var(--jp-text-color);
  font-family: var(--jp-font);
  font-size: 0.8em;
  font-weight: 400;
  display: inline-block;
}

.loading {
  visibility: hidden;
  height: 0;
}

.loading.show {
  visibility: visible;
  height: auto;
}

.logged-in {
  visibility: hidden;
  height: 0;
}

.logged-in.show {
  visibility: visible;
  height: auto;
}

.logged-out {
  visibility: hidden;
  height: 0;
}

.logged-out.show {
  visibility: visible;
  height: auto;
}

@media print {
  .jp {
    display: none !important;
  }
}
