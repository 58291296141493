body:has(.home) {
  background-image: url("./../img/yellow-paper.png");
}

.home {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
}

.home .logo {
  max-width: 300px;
  margin: 0 0 20px;
}

.home .logo img {
  margin: 0;
  max-width: 100%;
}

.home h1 {
  margin: 0 0 20px;
}

.home .logged-in {
  display: flex;
  flex-flow: column nowrap;
}

.home .logged-in button {
  margin: 30px 0 0;
}

#login-form {
  display: flex;
  flex-flow: column nowrap;
  max-width: 430px;
}

#login-form label {
  align-items: center;
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  margin: 15px 0;
}

#login-form .left-label {
  flex: 0 1 40%;
}

#login-form .jp {
  display: block;
}

#login-form label input {
  flex: 0 1 45%;
  width: 45%;
}

#login-form button {
  margin: 15px 0 0;
}

.error1,
.error2 {
  background-color: #f8c5c5;
  border: 2px solid var(--error-color);
  border-radius: 2px;
  margin: 20px 0 0;
  padding: 10px 20px;
  opacity: 0;
  transition: opacity 200ms linear;
}

.error1.show,
.error2.show {
  opacity: 1;
}

.home-feedback {
  margin: 20px 0 0;
  text-align: center;
}
